import { Component, Vue } from 'vue-property-decorator'
import FormObservationResidency from '@/components/ResidencyForm/Observation/Observation.vue'
import http from '@/services/http'
import ObservationResidency from '@/Models/ObservationResidency'
import Search from '@/components/Search/Search.vue'
import router from '@/router'
import Crm from '@/Models/Crm'
import ModalDelete from '@/components/ModalDelete/ModalDelete.vue'
import * as Helpers from '@/helpers'
import menuModule from '@/store/menu'
import Header from '@/components/Header/Header.vue'
import Animation from '@/components/Animation/index.vue'
@Component({
  components: {
    FormObservationResidency,
    Search,
    ModalDelete,
    Header,
    Animation,
  },
})
export default class ViewObservation extends Vue {
  showList = true
  data: Array<ObservationResidency> = []
  objObservationResidency: ObservationResidency = new ObservationResidency()
  action = 'add'
  member: Crm = new Crm()
  loading = false
  delete_modal_active = false
  currentPage = 1
  //th de la tabla
  header: Array<any> = [
    {
      field: 'date_read',
      label: 'Fecha',
    },
    {
      field: 'observation',
      label: 'Observacion',
    },
  ]
  mounted() {
    this.getObservationResidency()
    ;(this.$refs.header as any).isViewPrincipal = false
  }

  async getObservationResidency() {
    this.loading = true
    try {
      const id = menuModule.id_param
      if (id == null) throw router.push({ name: 'CCSS' }).catch(console.log)
      const res = await http.get(
        `/api/residency/arcr_residency_observations/${id}/`,
      )
      if (!res.data) throw new Error(res.data)
      console.log(res.data)
      if (res.data.observations != 'Esta residencia no tiene observaciones') {
        this.data = res.data.observations.map((obj: any) => ({
          id: obj.id,
          date: Helpers.dateParse(obj.date),
          date_read: Helpers.dateFormat(obj.date),
          observation: obj.observation,
        }))
      }
      this.member = res.data.member
      console.log('member', this.member)
    } catch (error) {
      //console.error(error)
      this.$buefy.toast.open({
        message: `Ups!, ha ocurrido un error`,
        type: 'is-danger',
      })
    }
    this.loading = false
  }

  editObj(props: any) {
    this.objObservationResidency = props
    this.showList = false
    this.action = 'edit'
  }
  async deleteObj() {
    try {
      await http.delete(
        `/api/residency/arcr_residency_observations/${this.objObservationResidency.id}/`,
      )
      this.data = this.data.filter(
        (a: ObservationResidency) => a.id !== this.objObservationResidency.id,
      )
      this.delete_modal_active = false
      this.getObservationResidency()
    } catch (error) {
      console.log(error)
      //toast.error("An error has occurred please try again.")
    }
  }
  deleteConfirm(props: any) {
    this.delete_modal_active = true
    this.objObservationResidency = props
  }
  closeModal() {
    this.objObservationResidency = new ObservationResidency()
    this.delete_modal_active = false
  }
  openForm() {
    this.showList = false
  }
  async cleanForm() {
    ;(this.$refs.header as any).showList = true
    this.objObservationResidency = new ObservationResidency()
    this.showList = true
    this.action = 'add'
    await this.getObservationResidency()
  }
  isData(data: any) {
    this.data = data
  }
}
